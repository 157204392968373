import React from "react";

import { Block, TrackedLink } from "..";

import CatGamer from "../../images/cat-gamer.svg";
import FloatingGamer from "../../images/floating-gamer-popcorn.svg";
import PartieLogo from "../../images/partie-logo.svg";
import PartiePlusLogo from "../../images/partie-plus-logo.svg";
import ZoomLogo from "../../images/zoom-logo.svg";

/**
 * Background assets.
 */
import Waves from "../../images/global/waves.svg";
import SpaceInvader01 from "../../images/space-invader-01.svg";
import SpaceInvader02 from "../../images/space-invader-02.svg";
import BluePlanet from "../../images/global/planet-blue.svg";
import PinkPlanet from "../../images/global/planet-pink.svg";

import "./get-started-stacked-block.scss";

const GetStartedStackedBlock = () => {
  return (
    <div className="get-started-stacked-block">
      <div className="get-started-stacked-block__background" aria-hidden>
        <img className="waves-1" src={Waves} alt="" aria-hidden loading="lazy" />
        <img className="waves-2" src={Waves} alt="" aria-hidden loading="lazy" />
        <img
          className="space-invader-01"
          src={SpaceInvader01}
          loading="lazy"
          alt=""
          aria-hidden
        />
        <img
          className="space-invader-02"
          src={SpaceInvader02}
          loading="lazy"
          alt=""
          aria-hidden
        />
        <img className="blue-planet" src={BluePlanet} alt="" aria-hidden loading="lazy" />
        <img className="pink-planet" src={PinkPlanet} alt="" aria-hidden loading="lazy" />
      </div>
      <div>
        <Block stacked lazy>
          <div className="partie-block__column" aria-hidden>
            <img src={CatGamer} alt="" aria-hidden loading="lazy" />
          </div>
          <div className="partie-block__column">
            <img src={PartieLogo} alt="Partie Logo" className="partie-logo" loading="lazy" />
            <h3 className="partie-block__title">
              Get free, secure access to our core features
            </h3>
            <p className="partie-block__description">
              Including Partie Matchmaking with Video and Voice
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="secondary-nav__link btn" to="https://app.partie.com" rel="noreferrer" children="Get Started" />
              <img src={ZoomLogo} alt="Powered by Zoom" className="zoom-logo" loading="lazy" />
            </div>
          </div>
        </Block>
        <Block stacked lazy>
          <div className="partie-block__column">
            <img
              src={PartiePlusLogo}
              alt="Partie Plus Logo"
              loading="lazy"
              className="partie-plus-logo"
            />
            <h3 className="partie-block__title">
              Get an enhanced Partie experience for one low monthly cost
            </h3>
            <p className="partie-block__description">
              Enjoy all the benefits of our Base Plan, along with access to
              Bounties, Tournaments, Clubs And Favors, only available with
              Partie Plus.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn" to="/plus" children="Get Partie Plus" />
            </div>
          </div>
          <div className="partie-block__column" aria-hidden>
            <img src={FloatingGamer} alt="" aria-hidden loading="lazy" />
          </div>
        </Block>
      </div>
    </div>
  );
};

export default GetStartedStackedBlock;
